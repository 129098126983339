<template>
  <div class="wrapper static-article">
    <row>
      <column
        xs="12"
        md="2">
      </column>
      <column
        xs="12"
        md="8">
        <h1 class="ff-flama fs-xxl fw-light tt-uppercase mb-5">
          Vilkår
        </h1>
        <div class="article-body clearfix mb-3">
          <h1 class="mt-0">Vilkår for bruk av <a href="https://www.europower.no">Europower.no</a></h1>
          <div class="mt-5">
            <strong>1. Området for disse generelle vilkårene</strong>
            <p> <br>
              Disse generelle vilkårene gjelder for papirutgaven av Europower, samt digitale publikasjoner og underliggende tjenester tilknyttet Europower (i det følgende også omtalt samlet som "Innholdet"). Innholdet utgis av Europower AS ("EP"). Ved å benytte Innholdet samtykker du til disse generelle vilkårene. Innholdet omfatter våre papirutgaver, nettsteder, apper, ulike typer tjenester, nyhetsbrev, varsler osv. Det henvises videre til separate abonnements- og brukervilkår, samt egne vilkår for bruker-ID.
            </p>
          </div>

          <div class="mt-5">
            <strong>2. Rettigheter</strong>
            <p> <br>
              EP har alle rettigheter til Innholdet slik som tekst, bilder, grafikk, logoer, lyd, videoer og alt annet materiale som publiseres av EP, dersom annet ikke uttrykkelig fremgår. Alle rettigheter til "Europower", "Energy Intelligence", "DN Media Group", samt alle andre varemerker, logoer, kjennetegn, forretningshemmeligheter og andre immaterielle rettigheter til Innholdet tilhører EP, om ikke annet er uttrykkelig fastsatt.
              <br><br>
              Som bruker har du kun rett til å benytte Innholdet for personlige og ikke-kommersielle formål. Du kan laste ned, linke til og skrive ut Innhold fra tjenestene for private og ikke-kommersielle formål, forutsatt at du respekterer eventuelle rettighetsnotiser og/eller -reservasjoner. Du kan ikke bruke Innhold for noen andre formål eller på noen andre måter enn de som er uttrykkelig tillatt.
              <br><br>
              All form for medieovervåkning, indeksering, salg eller videredistribusjon av hele eller deler av Innholdet utgitt av Europower AS krever gyldig avtale. Ta kontakt med EP for avtale om medieovervåkning.
            </p>
          </div>

          <div class="mt-5">
            <strong>3. Ansvarsbegrensninger</strong>
            <p> <br>
              EP tar forbehold om at det kan forekomme feil og unøyaktigheter i Innhold. EP fraskriver seg ethvert ansvar for dette. EP er heller ikke ansvarlig for tap som følge av feil på eller i tilknytning til Innholdet, nedetid, andre tekniske problemer eller feil i eller ved kilder som Innhold viser til eller henter informasjon fra.
              <br><br>
              Så langt tillatt etter gjeldende lovgivning fraskriver EP seg ethvert ansvar for tap som følge av forhold tilknyttet Innholdet.
            </p>
          </div>

          <div class="mt-5">
            <strong>4. Annonser</strong>
            <p> <br>
              Den enkelte annonsør har ansvaret for annonsene som finnes på Innholdet. EP har ikke noe ansvar for hva som fremgår i annonsene.
            </p>
          </div>

          <div class="mt-5">
            <strong>5. Linker</strong>
            <p> <br>
              Innholdet kan ha linker til informasjon og materiale på andre tjenester. EP har ikke noe ansvar for informasjonen og materialet som finnes på eller via slike linker.
            </p>
          </div>

          <div class="mt-5">
            <strong>6. Materiale du sender inn</strong>
            <p> <br>
              Som bruker kan du velge å sende inn materiale til EP. Ved å sende inn materiale gir du EP en ubetinget, ikke-eksklusiv, vederlagsfri, overførbar og evigvarende rett til å utgi materialet via Innholdet, i ethvert format, i enhver publikasjon tilknyttet EP, på enhver plattform tilknyttet EP, både eksisterende og de som måtte utvikles i fremtiden. EP gis videre rett til å foreta endringer i og redigere det mottatte materialet innenfor rammene av gjeldende lovgivning. Ved å sende inn materiale til EP innestår du for å ha alle nødvendige rettigheter til det aktuelle materialet.
              <br><br>
              Dersom det er nødvendig å klarere rettigheter til materialet du sender inn, plikter du å gjøre EP uttrykkelig oppmerksom på dette. Dersom dette ikke er gjort, har du som innsender ansvar for ethvert økonomisk tap EP lider som en følge av manglende rettigheter.
              <br><br>
              EP honorerer ikke innsendt materiale, med mindre det er inngått særskilt skriftlig avtale om dette.
            </p>
          </div>

          <div class="mt-5">
            <strong>7. Diskusjonsfora og lignende</strong>
            <p> <br>
              Dersom du deltar i diskusjonsfora eller lignende i tilknytning til Innholdet eller EP, oppfordrer EP deg til å følge alminnelig god folkeskikk. EP forbeholder seg retten til etter eget skjønn og på et hvilket som helst tidspunkt, å fjerne innhold som EP vurderer som støtende eller upassende på noen måte.
              <br><br>
              Du kan holdes ansvarlig for din opptreden på diskusjonsfora og lignende. EP forbeholder seg retten til å kreve erstattet eventuelle kostnader eller tap som EP lider på grunn av upassende opptreden.
              <br><br>
              EP har ikke noe ansvar for innholdet på diskusjonsfora eller lignende og oppfordrer til varsomhet ved bruk av slikt innhold.
            </p>
          </div>

          <div class="mt-5">
            <strong>8. Personvern</strong>
            <p><br>
              EPs personvernerklæring gjelder for behandling av personopplysninger i forbindelse med våre digitale tjenester. Personvernerklæringen <a href="https://privacy.nhst.no/personvernerklaering">finnes her</a>.
            </p>
          </div>

          <div class="mt-5">
            <strong>9. Kurser og tall</strong>
            <p> <br>
              Brukere av Innhold relatert til kurser og tall eller tilhørende funksjoner, plikter å akseptere følgende betingelser:
              <br><br>
              På våre digitale tjenester videreformidles kurser og tall. Det kan oppstå feil, mangler eller unøyaktigheter i informasjonen. EP innestår ikke for riktigheten og har ikke noe ansvar for informasjon, analyser, kurser eller tall som fremkommer fra eller formidles via disse tjenestene.
              <br><br>
              All bruk av informasjon, kurser, tall, finansielle data eller analyseverktøy på våre digitale tjenester til å gjøre eller ikke gjøre finansielle og ikke-finansielle transaksjoner, foretas utelukkende på brukerens eget ansvar og risiko. Bruker gjøres oppmerksom på at slik bruk er forbundet med risiko for tap.
              <br><br>
              EP fraskriver seg ethvert ansvar for finansielle og ikke-finansielle transaksjoner som gjøres eller ikke gjøres, basert på bruk av informasjon, kurser, tall, finansielle data eller analyseverktøy på våre digitale tjenester.
              <br><br>
              Bruker kan dermed ikke holde Europower AS, DN Media Group AS eller enheter som inngår i disse, eller våre informasjonsleverandører eller andre kilder ansvarlig for forsinkelser, feil eller mangler ved eller for bruk av informasjonen på de digitale tjenester.
              <br><br>
              Som bruker har du kun rett til å benytte våre digitale tjenester og materialet på disse for personlige og ikke-kommersielle formål.
              <br><br>
              Deler av innholdet som fremkommer på de digitale tjenestene, er eiet av den enkelte informasjonsleverandør og de innehar de immaterielle rettigheter til informasjonen. Det er ikke tillatt å videreformidle eller selge bearbeidet eller ikke-bearbeidet informasjon til andre, med mindre bruker har en gyldig skriftlig avtale med Europower AS som eksplisitt angir slike rettigheter.
              <br><br>
              All informasjon er beregnet utelukkende for personer bosatt i Norge og selskaper i Norge.
            </p>
          </div>

          <div class="mt-5">
            <strong>10. Endringer av disse vilkårene</strong>
            <p> <br>
              EP kan revidere disse generelle vilkårene når forhold nødvendiggjør dette, herunder på grunn av endringer i gjeldende lovgivning. Vi vil gjøre endringer godt synlig, men anbefaler at du leser de generelle vilkårene jevnlig.
            </p>
          </div>

          <div class="mt-5">
            <strong>11. Tvister</strong>
            <p><br>
              Tvister som måtte oppstå knyttet til Innhold eller de digitale tjenester skal avgjøres av norsk rett med Oslo tingrett som verneting.
            </p>
          </div>

          <div class="mt-5">
            <strong>12. Henvendelser</strong>
            <p> <br>
              Hvis du har spørsmål, kan du ta kontakt med oss på følgende e-postadresse: <a href="mailto:news@europower.no">news@europower.no</a>.
            </p>
          </div>

        </div>
      </column>
    </row>
  </div>
</template>

<script>
import { Page } from 'global-components';
export default {
  name: 'terms-page',
  extends: Page
};
</script>
